import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/visitor',
    name: 'Visitor',
    component: () => import('../views/Visitor.vue')
  },
  {
    path: '/sunday-service',
    name: 'Sunday-Service',
    component: () => import('../views/SundayService.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/admin/Login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
