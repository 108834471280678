window.axios = require('axios');

axios.interceptors.request.use(function (config) {
  // config.headers['Authorization'] = 'Bearer token here';
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import '@/assets/css/main.css'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
};
Vue.use(VueSweetalert2, options);

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'animate.css'
import helper from './helper.js'

import i18n from './i18n'

import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_CAPTHA_CLIENT
})

new Vue({
  mixins: [helper],
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
