import Vue from 'vue';
import moment from 'moment'
const helper = Vue.mixin({
    methods: {
        animateCss(element, animation, prefix = 'animate__') {
            return new Promise((resolve, reject) => {
                const animationName = `${prefix}${animation}`;

                element.classList.add(`${prefix}animated`, animationName, `${prefix}faster`);

                // When the animation ends, we clean the classes and resolve the Promise
                function handleAnimationEnd(event) {
                    event.stopPropagation();
                    element.classList.remove(`${prefix}animated`, animationName);
                    resolve('Animation ended');
                }

                element.addEventListener('animationend', handleAnimationEnd, { once: true });
            });
        },
        parseDate(inDate, format, custom) {

            if (inDate == null || inDate == undefined) {
                return '';
            }

            if (format == undefined) {
                format = 'DD MMM YYYY h:mm A'
            }

            if (custom == undefined) {
                custom = 'YYYY-MM-DD hh:mm:ss'
            }

            return moment(inDate, custom).format(format);
        }
    }
});

export default helper;