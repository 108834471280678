const en = {
    message: {
        connect_with_us: 'Connect With Us',
        please_fill: 'Please fill in your personal details below',
        about_you: 'About Yourself...',
        about_you_subtitle: 'Let us know a little more about yourself.',
        full_name: 'Full Name',
        gender: 'Gender',
        age: 'Age',
        email: 'Email',
        mobile: 'Mobile',
        home_tel: 'Home / Office Tel.',
        preferred_lang: 'Preferred Language',
        remarks_comments: 'Remarks or Comments',
        first_time_visiting: 'First Time Visiting',
        ethnicity: 'Ethnicity',
        religion_background: 'Religion Background',
        origin_church: 'Origin Church',
        visitation_purpose: 'Visition Purpose',
        occupation: 'Occupation',
        ipt: 'Education Institute',
        specify: 'Please Specify',
        submit: 'Submit',
        reset: 'Reset',
    }
}

export default en;