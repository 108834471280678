const bm = {
    message: {
        connect_with_us: 'Berhubung Dengan Kami',
        please_fill: 'Sila isi maklumat peribadi anda di bawah',
        about_you: 'Tentang Diri Anda...',
        about_you_subtitle: 'Perkenalkan serba-sedikit tentang diri anda.',
        full_name: 'Name Penuh',
        gender: 'Jantina',
        age: 'Umur',
        email: 'Email',
        mobile: 'Mobile',
        home_tel: 'Rumah / Tel. Pejabat',
        preferred_lang: 'Bahasa Pilihan',
        remarks_comments: 'Komen atau Catatan',
        first_time_visiting: 'Kali Pertama Datang?',
        ethnicity: 'Bangsa',
        religion_background: 'Latar Belakang Agama',
        origin_church: 'Gereja Asal',
        visitation_purpose: 'Tujuan Lawatan',
        specify: 'Nyatakan',
        occupation: 'Pekerjaan',
        ipt: 'Institut Pengajian',
        submit: 'Hantar',
        reset: 'Tetapkan Semula',
    }
}

export default bm;