<template>
  <div id="app">
    <div id="page-wrapper" class="relative">
      <header id="header">
        <div class="logo container">
          <div>
            "Responding In Unity To
            <span class="text-yellow-900"> Christ's</span> Mission"
          </div>
          <p class="text-lg">BEM (SIB) Olive Garden, Sentosa Parade</p>
        </div>
      </header>

      <div id="titleBar">
        <a href="javascript:void(0)" class="toggle" @click="toggleMenu"></a>
        <span class="title">Olive Connect</span>
      </div>

      <div id="navPanelMobile" class="fixed top-10 z-10 w-full hidden">
        <nav class="bg-white w-1/2">
          <ul>
            <li
              class="p-4"
              v-for="(tr, index) in menu"
              :key="index"
              @click="toggleMenu()"
            >
              <router-link :to="tr.url">{{ tr.name }}</router-link>
            </li>
            <li class="p-4">
              <a @click="mainSite()" href="javascript:void(0)">Main Website</a>
            </li>
          </ul>
        </nav>
      </div>

      <div id="nav">
        <ul>
          <li v-for="(tr, index) in menu" :key="index">
            <router-link :to="tr.url">{{ tr.name }}</router-link>
          </li>
          <li>
            <a @click="mainSite()" href="javascript:void(0)">Main Website</a>
          </li>
        </ul>
      </div>

      <section id="main-wrapper">
        <div id="main">
          <router-view> </router-view>
        </div>
      </section>

      <footer id="footer">
        <div class="container">
          <div class="row gtr-200">
            <div class="col-12">
              <!-- About -->
              <section>
                <h2 class="text-4xl mb-6">Contact Information</h2>
                <div class="flex flex-col">
                  <div class="font-bold text-lg">ADDRESS</div>
                  <div class="px-4 mb-6 text-gray-400">
                    Sublot 37, Tingkat 1, Block F, Sentosa Parade, 7th Mile,
                    Jalan Penrissen, Kota Sentosa, 93250 Kuching Sarawak,
                    Malaysia
                  </div>
                  <div>EMAIL</div>
                  <div class="font-bold text-xl text-gray-400">
                    admin@sibog.org
                  </div>
                </div>
              </section>
            </div>
            <div class="col-12">
              <!-- Contact -->
              <section>
                <h2 class="major"><span>Get in touch</span></h2>
                <ul class="contact">
                  <li>
                    <a
                      class="icon brands fa-facebook-f"
                      target="_blank"
                      href="https://facebook.com/bemolivegarden"
                      ><span class="label">Facebook</span></a
                    >
                  </li>
                  <li>
                    <a
                      class="icon brands fa-twitter"
                      href="https://twitter.com/bemolivegarden"
                      target="_blank"
                      ><span class="label">Twitter</span></a
                    >
                  </li>
                  <li>
                    <a
                      class="icon brands fa-instagram"
                      href="https://www.instagram.com/bemolivegarden/"
                      target="_blank"
                      ><span class="label">Instagram</span></a
                    >
                  </li>
                  <li>
                    <a
                      class="icon brands fa-youtube"
                      target="_blank"
                      href="https://youtube.com/c/BEMOliveGarden"
                      ><span class="label">Youtube</span></a
                    >
                  </li>
                </ul>
              </section>
            </div>
          </div>

          <!-- Copyright -->
          <div id="copyright">
            <ul class="menu">
              <li>&copy; BEM (SIB) Olive Garden 2021</li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import menu from "@/menu.js";
export default {
  name: "main-page",
  mounted() {},
  data() {
    return {
      menu: menu,
    };
  },
  mounted() {
    let doc = document.querySelector("link[rel='icon']");
    doc.setAttribute("href", require("@/assets/images/favicon.png"));
  },
  created() {
    document.title = process.env.VUE_APP_TITLE;
  },
  methods: {
    mainSite() {
      window.location.href = "https://sibog.org";
    },
    toggleMenu() {
      let elem = document.getElementById("navPanelMobile");

      if (!elem.classList.contains("hidden")) {
        this.animateCss(elem, "fadeOutUp").then(() => {
          elem.classList.toggle("hidden");
        });
      } else {
        elem.classList.toggle("hidden");
        this.animateCss(elem, "fadeInDown");
      }
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
